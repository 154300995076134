@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter&family=Outfit:wght@400;500;600;700&display=swap");

@layer components {
  html {
    @apply scroll-smooth w-screen box-content;
  }

  body {
    @apply text-[16px] text-slate-900;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    @apply font-outfit;
  }

  p {
    @apply leading-relaxed;
  }}
  